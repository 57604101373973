import { currentBusinessUnit } from 'lib/businessUnit';

export const isDevelopmentEnv = (): boolean => process.env.NODE_ENV === 'development';
export const isStagingEnv = (): boolean => process.env.NODE_ENV === 'production' && process.env.BNW_ENV === 'staging';
export const isProductionEnv = (): boolean => process.env.NODE_ENV === 'production'
  && process.env.BNW_ENV === 'production';
export const isTest = (): boolean => process.env.NODE_ENV === 'test';

export const onlyUSorLocalUStraffic = (): boolean => currentBusinessUnit().code === 'simplybusiness_us';
export const onlyUKtraffic = (): boolean => currentBusinessUnit().code === 'simplybusiness_uk';
export const shouldMaskPhoneNumber = (): boolean => onlyUSorLocalUStraffic();
export const shouldDisplayProductName = (): boolean => onlyUSorLocalUStraffic();

export const shouldShowClaimContactDetails = (): boolean => currentBusinessUnit().code === 'simplybusiness_uk';
export const showCertificateOfInsurance = (): boolean => onlyUSorLocalUStraffic();

export const shouldShowUKTilesList = (): boolean => currentBusinessUnit().code === 'simplybusiness_uk';

export const shouldShowCustomerAccountEligibility = (): boolean => true;

export const shouldShowVanPhaseOne = (): boolean => false;

export const isAndroid = (): boolean => /Android/i.test(navigator.userAgent);

export const isApple = (): boolean => /iPhone/i.test(navigator.userAgent);

export const shouldShowGoogleWalletButton = (): boolean => isAndroid();

export const shouldShowAppleWalletButton = (): boolean => isApple();

export const shouldShowWalletButton = (): boolean => shouldShowAppleWalletButton() || shouldShowGoogleWalletButton();

export const shouldShowChatbotExperiment = (): boolean => onlyUKtraffic() && process.env.BNW_ENV === 'production';

export const shouldAllowSelfServeMta = (): boolean => onlyUKtraffic() && ! isProductionEnv();

export const shouldUseAmplifyGen2 = (): boolean => isStagingEnv() || isDevelopmentEnv();

export default {
  isDevelopmentEnv,
  isStagingEnv,
  isProductionEnv,
  isTest,
  onlyUKtraffic,
  shouldMaskPhoneNumber,
  shouldDisplayProductName,
  onlyUSorLocalUStraffic,
  showCertificateOfInsurance,
  shouldShowClaimContactDetails,
  shouldShowUKTilesList,
  shouldShowCustomerAccountEligibility,
  shouldAllowSelfServeMta,
  shouldShowVanPhaseOne,
  isAndroid,
  shouldShowGoogleWalletButton,
  isApple,
  shouldShowAppleWalletButton,
  shouldShowWalletButton,
  shouldShowChatbotExperiment,
  shouldUseAmplifyGen2,
};
