import React, { lazy } from 'react';
import { Routes as RoutesWrapper, Route } from 'react-router-dom';
import { PrivateRoute } from 'components';
import { defaultUIState } from 'resources/ui/ducks';
import { defaultUserState } from 'resources/user/ducks';
import lazyRetry from 'services/lazyRetry';

const Policies = lazy(() => lazyRetry(() => import('./scenes/Policies'), 'scenes-policies'));
const SignIn = lazy(() => lazyRetry(() => import('./scenes/SignIn'), 'scenes-sign_in'));
const SignInWithOneTimeCode = lazy(() => lazyRetry(
  () => import('./scenes/SignInWithOneTimeCode'),
  'scenes-sign_in_with_one_time_code',
));
const SignInWithPassword = lazy(() => lazyRetry(
  () => import('./scenes/SignInWithPassword'),
  'scenes-sign_in_with_password',
));
const SignInWithToken = lazy(() => lazyRetry(() => import('./scenes/SignInWithToken'), 'scenes-sign_in_with_token'));
const SignInWithMfa = lazy(() => lazyRetry(() => import('./scenes/SignInWithMfa'), 'scenes-sign_in_with_mfa'));
const NotFound = lazy(() => lazyRetry(() => import('./scenes/NotFound'), 'scenes-not_found'));
const CreatePassword = lazy(() => lazyRetry(() => import('./scenes/CreatePassword'), 'scenes-create_password'));
const ConfirmEmail = lazy(() => lazyRetry(() => import('./scenes/ConfirmEmail'), 'scenes-confirm_email'));
const ForgotPassword = lazy(() => lazyRetry(() => import('./scenes/ForgotPassword'), 'scenes-forgot_password'));
const ResetForgottenPassword = lazy(() => lazyRetry(
  () => import('./scenes/ResetForgottenPassword'),
  'scenes-reset_forgotten_password',
));
const PolicyDetails = lazy(() => lazyRetry(() => import('./scenes/PolicyDetails'), 'scenes-policy_details'));
const PolicySearch = lazy(() => lazyRetry(() => import('./scenes/PolicySearch'), 'scenes-policy_search'));
const AccountDetails = lazy(() => lazyRetry(() => import('./scenes/AccountDetails'), 'scenes-account_details'));
const CancelPolicy = lazy(() => lazyRetry(() => import('./scenes/Cancellations'), 'scenes-cancel_policy'));
const PaymentDetails = lazy(() => lazyRetry(() => import('./scenes/PaymentDetails'), 'scenes-payment_details'));
const CertificateOfInsurance = lazy(() => lazyRetry(
  () => import('./scenes/CertificateOfInsurance'),
  'scenes-certificate_of_insurance',
));

export default function Routes() {
  return (
    <RoutesWrapper>
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-in/one-time-code" element={<SignInWithOneTimeCode email="" />} />
      <Route path="/sign-in/password" element={<SignInWithPassword email="" />} />
      <Route path="/sign-in/token" element={<SignInWithToken />} />
      <Route path="/sign-in/mfa" element={<SignInWithMfa />} />
      <Route path="/create-password" element={<CreatePassword ui={defaultUIState} />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-forgotten-password" element={<ResetForgottenPassword />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
      <Route path="/account-details" element={<PrivateRoute><AccountDetails /></PrivateRoute>} />
      <Route
        path="/policy-search"
        element={(
          <PrivateRoute>
            <PolicySearch user={defaultUserState} policyState={null} postcodeMismatch={false} />
          </PrivateRoute>
        )}
      />
      <Route path="/policy-details/:id" element={<PrivateRoute><PolicyDetails /></PrivateRoute>} />
      <Route path="/policy-details/:id/cancel" element={<PrivateRoute><CancelPolicy /></PrivateRoute>} />
      <Route path="/payment-details" element={<PrivateRoute><PaymentDetails /></PrivateRoute>} />
      <Route path="/certificate-of-insurance" element={<PrivateRoute><CertificateOfInsurance /></PrivateRoute>} />
      <Route path="/" element={<PrivateRoute><Policies /></PrivateRoute>} />
      <Route path="*" element={<NotFound />} />
    </RoutesWrapper>
  );
}
