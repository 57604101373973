import { Amplify } from 'aws-amplify';

const init = (): void => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: process.env.CUSTOMER_ACCOUNT_USER_POOL_ID,
        userPoolClientId: process.env.CUSTOMER_ACCOUNT_WEB_CLIENT_ID,
        identityPoolId: process.env.CUSTOMER_ACCOUNT_IDENTITY_POOL_ID,
        loginWith: {
          email: true,
        },
      },
    },
  });
};

export default { init };
