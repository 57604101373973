import Auth from '@aws-amplify/auth';
import {
  signIn as signInGen2, signOut as signOutGen2, confirmResetPassword, fetchAuthSession, AuthSession, SignInOutput,
} from 'aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  CurrentUserInfo,
  USER__DELETE_CURRENT_JWT_TOKEN,
  USER__STORE_CURRENT_JWT_TOKEN,
  storeCurrentUserInfo,
} from 'resources/user/ducks';
import { getUser } from 'resources/user/selectors';
import store from 'boot/store';
import newRelic from 'lib/newRelic';
import { resetPolicies } from 'resources/policies/ducks';
import configurationService from './ConfigurationService';

let cognitoUser: CognitoUser;

const sanitisedPassword = (password: string): string => password.trim();

const sanitisedEmail = (email: string): string => email.toLowerCase();

const codeDestination = (response: SignInOutput) => {
  if (response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
    || response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE') {
    return response.nextStep.codeDeliveryDetails?.destination;
  }

  return null;
};

export const buildMfaUrl = (response: SignInOutput, source: string) => {
  const nextStep = response.nextStep.signInStep;
  const baseUrl = `/sign-in/mfa?sign_in_step=${nextStep}&sign_in_source=${source}`;

  const destination = codeDestination(response);

  return `${baseUrl}${destination ? `&code_destination=${destination}` : ''}`;
};

// issue on local when signing in with gen 2, cognito keys are present which throws an error when calling the function
const clearCognitoKeys = (): void => {
  Object.keys(localStorage)
    .filter(key => key.startsWith('CognitoIdentityServiceProvider'))
    .forEach(key => localStorage.removeItem(key));
};

const extractSession = (session: AuthSession): CurrentUserInfo => {
  if (! session.tokens || ! session.tokens.accessToken || ! session.tokens.idToken) {
    throw new Error('User is not signed in');
  }

  const accessToken = { jwtToken: session.tokens.accessToken.toString() };
  const idToken = { jwtToken: session.tokens.idToken.toString() };
  const attributes = { email_verified: session.tokens.idToken.payload.email_verified === true || false };

  return { signInUserSession: { accessToken, idToken }, attributes };
};

export async function getCurrentUserInfo(): Promise<CurrentUserInfo> {
  if (configurationService.isProductionEnv()) {
    return Auth.currentAuthenticatedUser({ bypassCache: true });
  }

  const session = await fetchAuthSession();

  return extractSession(session);
}

export const verifyChangedEmail = async (code: string): Promise<string> => (
  Auth.verifyCurrentUserAttributeSubmit('email', code)
);

export const signIn = async (
  email: string, password: string, checkVerifiedEmail = false,
): Promise<void | SignInOutput> => {
  let signInResponse;

  if (configurationService.shouldUseAmplifyGen2()) {
    clearCognitoKeys();
    signInResponse = await signInGen2({ username: sanitisedEmail(email), password: sanitisedPassword(password) });
  } else {
    await Auth.signIn(sanitisedEmail(email), sanitisedPassword(password));
  }

  const currentUserInfo = await getCurrentUserInfo();

  if (checkVerifiedEmail && currentUserInfo.attributes) {
    const emailVerified = currentUserInfo.attributes.email_verified;
    const normalizedEmailVerified = emailVerified.toString().toLowerCase();

    if (normalizedEmailVerified !== 'true') {
      throw new Error('This email address has not been verified yet');
    }
  }
  store.dispatch({
    type: USER__STORE_CURRENT_JWT_TOKEN,
    payload: currentUserInfo,
  });

  return signInResponse;
};

export const sendEmailWithCode = async (email: string): Promise<void> => {
  cognitoUser = await Auth.signIn(sanitisedEmail(email));
};

export const signInWithOneTimeCode = async (code: number | string): Promise<void> => {
  await Auth.sendCustomChallengeAnswer(cognitoUser, code.toString());
  const currentUserInfo = await Auth.currentAuthenticatedUser();

  store.dispatch({
    type: USER__STORE_CURRENT_JWT_TOKEN,
    payload: currentUserInfo,
  });
};

export const resetForgottenPassword = async (
  email: string,
  code,
  password: string,
): Promise<void> => {
  if (configurationService.shouldUseAmplifyGen2()) {
    await confirmResetPassword({ username: email, confirmationCode: code, newPassword: sanitisedPassword(password) });
  } else {
    await Auth.forgotPasswordSubmit(email, code, sanitisedPassword(password));
  }
};

export const refreshUserSession = async (): Promise<void> => {
  if (configurationService.shouldUseAmplifyGen2()) {
    fetchAuthSession({ forceRefresh: true })
      .then((session) => {
        storeCurrentUserInfo(extractSession(session));
      })
      .catch((err) => {
        newRelic.logError(err);
      });
  } else {
    Auth.currentAuthenticatedUser()
      .then(user => user.refreshSession(
        user.signInUserSession.refreshToken,
        (err, session) => {
          storeCurrentUserInfo({ signInUserSession: session, attributes: { email_verified: false } });
        },
      ))
      .catch((err) => {
        newRelic.logError(err);
      });
  }
};

export const getJwT = (): string | null => {
  const userState = getUser(store.getState());

  return userState.token;
};

export const deleteUserInfo = (): void => {
  store.dispatch({
    type: USER__DELETE_CURRENT_JWT_TOKEN,
  });
};

export const signOut = async (): Promise<void> => {
  try {
    if (configurationService.shouldUseAmplifyGen2()) {
      await signOutGen2();
    } else {
      await Auth.signOut();
    }
  } finally {
    deleteUserInfo();
    store.dispatch(resetPolicies());
  }
};

export const getUserInfo = (): Object => getUser(store.getState());
